import React, { useEffect, useState } from "react"
import { Box, Heading } from "grommet"
import { graphql } from "gatsby"
import styled from "styled-components"
import { navigate } from "gatsby"
import Layout from "../../../containers/layout"
import Image from "../../../components/Image"
import { format, parseISO } from 'date-fns'
import CardList from "../../../components/CardListing/NewCardList"
import querystring from "query-string"
import Ad from "../../../containers/Ad"
import { html2react } from '../../../helpers/htmltoreact'

const ImageContainer = styled.div`
  cursor: pointer;
`

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const Component = ({ data, location }) => {
  const renderImage = (item, index) => {
    return (

      <ImageContainer
      ><Box> 
        <Image
          src={item.image}
          imageProps={{
            onClick: () => {
              navigate(`/videos/view?id=${item.videoId}`)
            },
            fit: "cover",
            fill: "true",
          }}
        />
        <Box direction="row" gap="small" justify="center" height="48px" overflow="hidden">
        {html2react(item.title)}
        <a href={`/videos/channel?id=${item.channelId}`}>More</a>
        </Box>

         <br />
        
        </Box>
      </ImageContainer>
    )
  }
  const d = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) 


  const [datasource, setDatasource] = useState([])
  const [channels, setChannels] = useState([])
  const [recentVideoDS, setRecentVideoDS] = useState([])
  useEffect(() => {
    let result = []

    const allVideos = data.allAggVideo.edges;

    const recentVideos = allVideos.map(({ node }) => {
      console.log(node)
      return {
        id: node.key,
        image: node?.thumbnails?.high?.url,
        title: node.title,
        publishTime: node.publishTime,
        channelId: node.channelId,
        videoId: node.key
      }
    })
    .filter(n => n.publishTime > d.toISOString())

    const newDS = data.allChannel.edges.map((channel) => {
      return data.allAggVideo.edges
      .find(({ node }) => node.channelId === channel.node.channelId)
    })
    .filter(i => i)
    .map(({ node }) => ({
      ...node
    }))
    .map(channel => {
      const featuredVideo = allVideos.find(({ node }) => channel.channelId === node.channelId )
      return {
        ...channel,
        videoId: featuredVideo.node.key,
        image: featuredVideo.node?.thumbnails?.high?.url
      }
    })

    setChannels(data.allChannel)
    setDatasource(newDS)
    setRecentVideoDS(recentVideos)
  }, [data.allAggVideo.edges])
  return (
    <>
      {
        <Layout max="100%">

          <Box>
            {
              recentVideoDS.length > 0 && <h3>Recent Video</h3>
            }
            <CardList dataList={recentVideoDS} render={renderImage} />
            <Ad />
            <Heading level={3}  margin="none">Feng Shui Channel (Source From Youtube)
            </Heading>
            <p> To Add/Remove Listing, Please <a href="mailto:openfengshui@gmail.com">email</a> us</p>
            <CardList dataList={datasource} render={renderImage} />
            
          </Box>

          
        </Layout>
      }
    </>
  )
}

export default Component

export const query = graphql`
query MyQuery333 {
  allAggVideo(sort: {fields: publishedAt, order: DESC}) {
    edges {
      node {
        key
        channelId
        title
        thumbnails {
          high {
            url
          }
        }
        publishedAt
        publishTime
      }
    }
  }

  allChannel {
    edges {
      node {
        id
        title
        channelId
      }
    }
  }
}
`
