import React from "react";

var HtmlToReactParser = require("html-to-react").Parser;
var HtmlToReact = require("html-to-react");
var htmlToReactParser = new HtmlToReactParser();
var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

var isValidNode = function () {
  return true;
};

var processingInstructions = [
  {
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

export const html2react = (htmlInput) =>
  htmlToReactParser.parseWithInstructions(
    htmlInput,
    isValidNode,
    processingInstructions
  );